import { adaptLCAFormToAPI } from '../../../adapters/lca';
import { FormProcessInput } from '../../../components/lca/Scenarios/components/ConfigureProcessModal/FormContext';
import { LCAFormMaterial } from '../../../components/lca/Scenarios/components/SelectMaterialModal/constants';
import type { LCABlockBack } from '../../../components/lca/types/LCABlock';
import { LCAItem } from '../../../components/lca/types/LCAItem';
import apiFetch from '../../../utils/apiFetch';
import { generateQueryParamsFromObject, getUrl, IFilter } from '../../../utils/url';

const URL = '/lca/portfolio';

type ILCAPostResponse = {
  acv_id: string;
  process: LCABlockBack;
  final_product: LCABlockBack;
};

export const createLCABackbone = async (data: ILCAFormType): Promise<ILCAPostResponse> => {
  const response = await apiFetch('POST', URL, adaptLCAFormToAPI(data));

  return response.data as ILCAPostResponse;
};

export type LCAInput = {
  id: string;
  name: string;
  type: 'raw_material' | 'consumption' | 'energy';
  supplier: string;
};

export type LCAMaterial = {
  id: string;
  material_id: string;
  country: string;
  location: string;
  impact_source_id: string;
  name: string;
  type: 'material' | 'energy';
  supplier: string;
  unit_list: { unit_id: string; unit_name: string }[];
};

export type ProcessInput = LCAInput & {
  material_id: string;
  quantity: number;
  unit: string;
  unit_id: string;
  unit_list: { unit_id: string; unit_name: string }[];
};

export type LCAProcessMetadata = {
  node_id: string;
  entity_id?: string;
  name?: string;
  reference_output?: string;
  inputs?: ProcessInput[];
  country?: string;
};

export type LCAMaterialMetadata = {
  node_id: string;
  name?: string;
  items?: LCAFormMaterial[];
};

type LCAMetadataResponse = {
  process: LCAProcessMetadata[];
  material: LCAMaterialMetadata[];
};
export const getLCAMetadata = async (acv_id: string): Promise<LCAMetadataResponse> => {
  const response = await apiFetch('GET', `/lca/${acv_id}/nodes/metadata`);

  return response.data as LCAMetadataResponse;
};

type IGetLCAMaterialsParams = {
  page: number;
  filters?: IFilter[];
};

type IGetLCAMaterialsResponse = {
  items: LCAMaterial[];
  page: number;
  total: number;
};

export const getLCAMaterials = async ({
  page,
  filters
}: IGetLCAMaterialsParams): Promise<IGetLCAMaterialsResponse> => {
  const response = await apiFetch(
    'GET',
    getUrl('/lca/materials', {
      filters,
      queryParams: generateQueryParamsFromObject({ page, size: 10 })
    })
  );

  return response.data as IGetLCAMaterialsResponse;
};

type ILCAPatchMaterialNode = {
  acv_id: string;
  node_id: string;
  name: string;
  materials: LCAFormMaterial[];
};

type ILCAPatchMaterialNodeResponse = {
  node_id: string;
  name: string;
  items: LCAFormMaterial[];
};

export const patchLCAMaterialNode = async ({
  acv_id,
  node_id,
  name,
  materials
}: ILCAPatchMaterialNode) => {
  const response = await apiFetch('PATCH', `/lca/${acv_id}/material/${node_id}`, {
    name,
    materials
  });

  return response.data as ILCAPatchMaterialNodeResponse;
};

type ILCAPostReferenceOutput = {
  acv_id: string;
  node_id: string;
  name: string;
  quantity: number;
  unit_id: string;
};

type ILCAPostReferenceOutputResponse = {
  name: string;
  quantity: number;
  unit: Unit;
};

export const postLCAReferenceOutput = async ({
  acv_id,
  node_id,
  name,
  quantity,
  unit_id
}: ILCAPostReferenceOutput) => {
  const response = await apiFetch('POST', `/lca/${acv_id}/process/${node_id}/reference_output`, {
    name,
    quantity,
    unit_id
  });

  return response.data as ILCAPostReferenceOutputResponse;
};

type PatchLCAProcessNodeParams = {
  acv_id: string;
  nodeId: string;
  form: {
    name: string;
    country: SelectOptionFormat<string>;
    inputs: FormProcessInput[];
  };
};

type PatchLCAProcessNodeResponse = {
  node_id: string;
  name: string;
  country: string;
  inputs: ProcessInput[];
};

export const patchLCAProcessNode = async ({ acv_id, nodeId, form }: PatchLCAProcessNodeParams) => {
  const response = await apiFetch('PATCH', `/lca/${acv_id}/process/${nodeId}`, {
    name: form.name,
    country: form.country.id,
    inputs: form.inputs.map((input) => ({
      material_id: input.material_id,
      quantity: input.quantity,
      unit_id: input.unit.id
    }))
  });

  return response.data as PatchLCAProcessNodeResponse;
};

type GetLCAImpactsParams = {
  acv_id: string;
  methodology_id: string;
  impact_category: string;
};

export type NodeImpact = {
  block_id: string;
  sum_total_impact: number;
  impact_general_unit_id: string;
  impact_specific_unit?: string;
};

type GetLCAImpactsResponse = {
  items: NodeImpact[];
};

export const getLCAImpacts = async ({
  acv_id,
  methodology_id,
  impact_category
}: GetLCAImpactsParams) => {
  const block_chain = await apiFetch('GET', `/lca/block_chain/${acv_id}`);

  const response = await apiFetch(
    'GET',
    getUrl(`/lca/impacts/${acv_id}`, {
      queryParams: generateQueryParamsFromObject({
        methodology_id,
        impact_category,
        process_id_list: JSON.stringify(block_chain.data.items)
      })
    })
  );

  return response.data as GetLCAImpactsResponse;
};

export const deleteLCA = async (acv_id: string) => {
  await apiFetch('DELETE', `/lca/portfolio/${acv_id}`);
};

type IPatchLCAParams = {
  acv_id: string;
  data: ILCAFormType;
};
export const patchLCA = async ({ acv_id, data }: IPatchLCAParams) => {
  const response = await apiFetch('PATCH', `/lca/portfolio/${acv_id}`, adaptLCAFormToAPI(data));

  return response.data as LCAItem;
};

type DuplicateLCAParams = {
  acv_id: string;
};

export const duplicateLCA = async ({ acv_id }: DuplicateLCAParams) => {
  const response = await apiFetch('POST', `/lca/duplicate/${acv_id}`);

  return response.data as boolean;
};
