import { useTranslation } from 'react-i18next';
import useSelectedOrganization from '../../customHooks/useSelectedOrganization';
import Button from '../ui/button/Button';

const WebinarSection = () => {
  const { t } = useTranslation();

  const foundOrganization = useSelectedOrganization();
  const navigateToWebinar = () => {
    window.open(
      'https://es.dcycle.io/webinars/csrd?utm_source=banner-saas&utm_medium=saas&utm_campaign=webinar-csrd',
      '_blank'
    );
  };

  if (!!foundOrganization && foundOrganization.subscription_plan === 'free_plan') {
    return null;
  }

  return (
    <div className='subscription-reminder main-bg-color'>
      <div className='subscription-reminder-content'>
        <h5 className='on-light-text-color subtitle3-font'>{t('webinarTitle')}</h5>
        <Button
          lookAndFeel='primary'
          text={t('webinarButton')}
          onClick={navigateToWebinar}
          size='tiny'></Button>
      </div>
    </div>
  );
};

export default WebinarSection;
