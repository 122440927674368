import {
  ColumnAPI,
  LanguageType,
  OnEntryChange,
  OnEntryInit,
  RejectSubmitResult
} from 'nuvo-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useErrors from '../../../../../../../../customHooks/useErrors';
import useNuvoFileName from '../../../../../../../../customHooks/useNuvoFileName';
import { uploadFilePresignedUrl } from '../../../../../../../../services/api/aws';
import { postNuvoAnalytics } from '../../../../../../../../services/api/nuvoAnalytics';
import { getPresignedUrlWaste } from '../../../../../../../../services/api/waste';
import { basicNuvoStyle } from '../../../../../../../../styles/nuvo';
import { convertStringToDateBackend } from '../../../../../../../../utils/convertDates';
import {
  transformNuvoResultsIntoCsv,
  validateDateFields
} from '../../../../../../../../utils/nuvo';
import { sessionStorageSetItem } from '../../../../../../../../utils/sessionStorage';
import NuvoImporter from '../../../../../../../ui/nuvoImporter/NuvoImporter';
import useLerRdCodes from './useGetLerRdCodes';
import useNuvoButton from '../../../../../../../../customHooks/useNuvoButton';

type Props = {
  handleClose: () => void;
};

export const InputNuvoWastes = ({ handleClose }: Props) => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  const lookAndFeel = 'primary';
  const ERRORS = useErrors();

  const { lerCodes, rdCodes } = useLerRdCodes();

  const { fileName, handleGetFileName, handleExit } = useNuvoFileName({ lookAndFeel });
  const onClick = () => {
    handleGetFileName();
    sessionStorageSetItem('isNuvoOpen', true);
  };
  useNuvoButton({ onClick });

  const nuvoError = new RejectSubmitResult(
    ERRORS.NUVO.GENERAL_ERROR_TITLE,
    ERRORS.NUVO.GENERAL_ERROR_MESSAGE
  );

  const nuvoErrorFounded = new RejectSubmitResult(
    ERRORS.NUVO.ERRORS_FOUNDED_TITLE,
    ERRORS.NUVO.ERRORS_FOUNDED_MESSAGE
  );

  const columns: ColumnAPI[] = [
    {
      key: 'identifier',
      label: t('templates.wastes.id'),
      columnType: 'string',
      example: '1234A'
    },
    {
      key: 'ler_code',
      label: t('templates.wastes.lerCode'),
      columnType: 'category',
      dropdownOptions: lerCodes,
      validations: [
        {
          validate: 'required'
        }
      ],
      example: '010102'
    },
    {
      key: 'rd_code',
      label: t('templates.wastes.rdCode'),
      columnType: 'category',
      dropdownOptions: rdCodes,
      example: 'D01'
    },
    {
      key: 'quantity',
      label: t('templates.wastes.quantity'),
      columnType: 'int',
      validations: [
        {
          validate: 'required'
        }
      ],
      example: '180'
    },
    {
      key: 'start_date',
      label: t('templates.wastes.startDate'),
      columnType: 'date',
      validations: [
        {
          validate: 'required'
        }
      ],
      outputFormat: 'YYYY-MM-DD',
      example: '2021-01-01'
    },
    {
      key: 'end_date',
      label: t('templates.wastes.endDate'),
      columnType: 'date',
      outputFormat: 'YYYY-MM-DD',
      example: '2021-01-31'
    },
    {
      key: 'km_to_center',
      label: t('templates.wastes.kmToCenter'),
      columnType: 'int',
      example: '10'
    }
  ];

  const category = 'wastes';

  const onEntryChangeRow: OnEntryInit = (row) => {
    if (!row || !row.start_date || !row.ler_code || !row.quantity) return;
    const new_row = {} as any;

    if (Number(row.quantity) < 0) {
      new_row.quantity = {
        value: row.quantity,
        info: [
          {
            level: 'error',
            message: t('templates.shipments.errors.quantity_kg')
          }
        ]
      };
    }

    if (row.km_to_center && Number(row.km_to_center) < 0) {
      new_row.km_to_center = {
        value: row.km_to_center,
        info: [
          {
            level: 'error',
            message: t('templates.shipments.errors.distance_km')
          }
        ]
      };
    }

    if (convertStringToDateBackend(row.start_date as string) > new Date()) {
      new_row.start_date = {
        value: row.start_date,
        info: [
          {
            level: 'error',
            message: ERRORS.NUVO.START_DATE_IN_FUTURE
          }
        ]
      };
    }

    if (convertStringToDateBackend(row.end_date as string) > new Date()) {
      new_row.end_date = {
        value: row.end_date,
        info: [
          {
            level: 'error',
            message: ERRORS.NUVO.END_DATE_IN_FUTURE
          }
        ]
      };
    }

    if (
      convertStringToDateBackend(row.start_date as string) >
      convertStringToDateBackend(row.end_date as string)
    )
      new_row.end_date = {
        value: row.end_date,
        info: [
          {
            message: ERRORS.NUVO.END_DATE_BEFORE_START_DATE,
            level: 'error'
          }
        ]
      };

    if (!row?.end_date)
      new_row.end_date = {
        value: row.start_date,
        info: []
      };

    const minYearErrors = validateDateFields(['start_date', 'end_date'])(row);

    for (const key in minYearErrors) {
      new_row[key] = minYearErrors[key];
    }

    return new_row;
  };

  const onEntryChange: OnEntryChange = (rows) => {
    return rows
      .filter((row) => Object.keys(onEntryChangeRow(row.data, row.rowIndex) ?? {}).length > 0)
      .map((row) => {
        return {
          rowIndex: row.rowIndex,
          data: {
            ...row.data,
            ...onEntryChangeRow(row.data, row.rowIndex)
          }
        };
      });
  };

  return (
    <NuvoImporter
      lookAndFeel={lookAndFeel}
      btnI18nKey='businessTravels.uploadAuto'
      settings={{
        language: i18n.resolvedLanguage as LanguageType,
        style: basicNuvoStyle,
        automaticHeaderDetection: true,
        maxEntries: 700_000,
        identifier: 'wastes_facility_Dcycle',
        columns,
        enableExamples: true
      }}
      onEntryInit={onEntryChangeRow}
      onEntryChange={onEntryChange}
      onCancel={() => {
        handleExit();
        sessionStorageSetItem('isNuvoOpen', false);
      }}
      onResults={async (results, errors, complete) => {
        // cannot submit file with errors
        if (errors.length > 0) return complete(nuvoErrorFounded);

        if (results.length <= 0) return complete(nuvoError);

        const newResults = results.map((prev) => ({
          ...prev,
          unit: 'kg',
          facility_id: id
        }));

        const transformNewResults = newResults.map(() => ({
          quantity: '-',
          unit_id: 'kg',
          total_km_to_waste_center: '-',
          destination: '-',
          start_date: undefined,
          end_date: undefined,
          uploaded_by: '-',
          facility_id: '-',
          status: 'loading'
        }));

        // transform results into csv string
        const content = transformNuvoResultsIntoCsv(newResults);

        const finalFileName = `${fileName || category}.csv`;

        // transform content to File
        const file = new File([content], finalFileName, { type: 'text/csv' });

        // get presinged url
        const data = await getPresignedUrlWaste(finalFileName);

        // error uploading file
        if (!data || data?.response?.status >= 400) return complete(nuvoError);

        // upload file to presigned url
        const response = await uploadFilePresignedUrl(file, data?.upload_url);

        // error uploading file
        if (!response) return complete(nuvoError);

        // analytics
        await postNuvoAnalytics({
          numberOfRows: results.length,
          fileName: finalFileName,
          category
        });

        complete();

        setTimeout(() => {
          handleClose();
          sessionStorageSetItem('isNuvoOpen', false);
        }, 5000);
      }}
    />
  );
};
